import React from "react";
//import {Link} from 'gatsby'
import logo from "../../assets/images/logo.png";
import footerMap from "../../assets/images/footer-map.png";
import { Link, Trans } from "gatsby-plugin-react-i18next";

const Footer = () => {

  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer-area bg-color">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-sm-6">
            <div className="single-footer-widget">
              <a href="/" className="logo">
                <img src={logo} alt="logo" />
              </a>
              {/*<p><Trans i18nKey="footer.info">info</Trans></p>*/}

              {/*<ul className="social-link">*/}
              {/*  /!*<li>*!/*/}
              {/*  /!*    <Link to="#" className="d-block" target="_blank" rel="noreferrer">*!/*/}
              {/*  /!*        <i className='bx bxl-facebook'></i>*!/*/}
              {/*  /!*    </Link>*!/*/}
              {/*  /!*</li>*!/*/}
              {/*  /!*<li>*!/*/}
              {/*  /!*    <Link to="#" className="d-block" target="_blank" rel="noreferrer">*!/*/}
              {/*  /!*        <i className='bx bxl-twitter'></i>*!/*/}
              {/*  /!*    </Link>*!/*/}
              {/*  /!*</li>*!/*/}
              {/*  /!*<li>*!/*/}
              {/*  /!*    <Link to="#" className="d-block" target="_blank" rel="noreferrer">*!/*/}
              {/*  /!*        <i className='bx bxl-instagram'></i>*!/*/}
              {/*  /!*    </Link>*!/*/}
              {/*  /!*</li>*!/*/}
              {/*  <li>*/}
              {/*    <Link to="#" className="d-block" target="_blank" rel="noreferrer">*/}
              {/*      <i className="bx bxl-linkedin"></i>*/}
              {/*    </Link>*/}
              {/*  </li>*/}
              {/*</ul>*/}
            </div>
          </div>

          <div className="col-lg-2 col-sm-6">
            <div className="single-footer-widget pl-5">
              {/*<h3><Trans i18nKey="footer.explore">Explore</Trans></h3>*/}

              {/*<ul className="footer-links-list">*/}
              {/*  <li>*/}
              {/*    <Link to="/">*/}
              {/*      <Trans i18nKey="navBar.home">Home</Trans>*/}
              {/*    </Link>*/}
              {/*  </li>*/}
              {/*  <li>*/}
              {/*    <Link to="/about-us">*/}
              {/*      <Trans i18nKey="navBar.aboutUs">About</Trans>*/}
              {/*    </Link>*/}
              {/*  </li>*/}
              {/*  /!*<li>*!/*/}
              {/*  /!*  <Link to="/case-studies">*!/*/}
              {/*  /!*    <Trans i18nKey="navBar.caseStudies">Case Studies</Trans>*!/*/}
              {/*  /!*  </Link>*!/*/}
              {/*  /!*</li>*!/*/}
              {/*  /!*<li>*!/*/}
              {/*  /!*    <Link to="/blog">*!/*/}
              {/*  /!*        Our Blog*!/*/}
              {/*  /!*    </Link>*!/*/}
              {/*  /!*</li>*!/*/}
              {/*  <li>*/}
              {/*    <Link to="/contact">*/}
              {/*      <Trans i18nKey="navBar.contact">Contact</Trans>*/}
              {/*    </Link>*/}
              {/*  </li>*/}
              {/*</ul>*/}
            </div>
          </div>

          <div className="col-lg-2 col-sm-6">
            <div className="single-footer-widget">
              {/*<h3><Trans i18nKey="footer.resources">Resources</Trans></h3>*/}

              {/*<ul className="footer-links-list">*/}
              {/*  /!*<li>*!/*/}
              {/*  /!*    <Link to="/team">*!/*/}
              {/*  /!*        Our Scientists*!/*/}
              {/*  /!*    </Link>*!/*/}
              {/*  /!*</li>*!/*/}
              {/*  <li>*/}
              {/*    <Link to="/services">*/}
              {/*      <Trans i18nKey="navBar.services">Services</Trans>*/}
              {/*    </Link>*/}
              {/*  </li>*/}
              {/*  /!*<li>*!/*/}
              {/*  /!*  <Link to="/testimonials">*!/*/}
              {/*  /!*    Testimonials*!/*/}
              {/*  /!*  </Link>*!/*/}
              {/*  /!*</li>*!/*/}
              {/*  /!*<li>*!/*/}
              {/*  /!*    <Link to="/services">*!/*/}
              {/*  /!*        SaaS Solutions*!/*/}
              {/*  /!*    </Link>*!/*/}
              {/*  /!*</li>*!/*/}
              {/*  <li>*/}
              {/*    <Link to="/case-studies">*/}
              {/*      <Trans i18nKey="navBar.caseStudies">Case Studies</Trans>*/}
              {/*    </Link>*/}
              {/*  </li>*/}
              {/*</ul>*/}
            </div>
          </div>

          <div className="col-lg-4 col-sm-6">
            <div className="single-footer-widget">
              <h3><Trans i18nKey="footer.address">Address</Trans></h3>

              <ul className="footer-contact-info">
                <li>
                  <i className="bx bx-map"></i>
                  Al. Komisji Edukacji Narodowej 36/112B<br />
                  02-797 Warszawa <br />
                  Polska
                </li>
                <li>
                  <i className="bx bx-phone-call"></i>
                  <a href="tel:+48602226038">+48 602 226 038</a>
                </li>
                <li>
                  <i className="bx bx-envelope"></i>
                  <a href="mailto:contact@ecommercebox.io">contact@ecommercebox.io</a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="footer-bottom-area">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6">
              <p><Trans i18nKey="common:footer.copyright">Copyright</Trans> @{currentYear} <strong>ECBOX sp. z o.o.</strong></p>
            </div>

            <div className="col-lg-6 col-md-6">
              <ul>
                {/*<li>*/}
                {/*  <Link to="/privacy-policy">*/}
                {/*    <Trans i18nKey="common:footer.privacyPolicy">Privacy Policy</Trans>*/}
                {/*  </Link>*/}
                {/*</li>*/}
                {/*<li>*/}
                {/*  <Link to="/terms-of-service">*/}
                {/*    <Trans i18nKey="footer.termsConditions">Terms & Conditions</Trans>*/}
                {/*  </Link>*/}
                {/*</li>*/}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-map">
        <img src={footerMap} alt="footer-logo" />
      </div>
    </footer>
  );
};

export default Footer;
