import React from "react";
import { Helmet } from "react-helmet";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";

const SEO = () => {
  const {t} = useTranslation();
  return (
    <div>
      <Helmet>
        <title>{t('seo.title')}</title>
        <meta name="description"
              content={t('seo.desc')} />
        <meta name="og:title" property="og:title" content={t('seo.title')}></meta>
        <meta name="twitter:card" content={t('seo.title')}></meta>
        <link rel="canonical" href="https://ecommercebox.io/"></link>
        {/*<meta property="og:image"*/}
        {/*      content="https://res.cloudinary.com/dev-empty/image/upload/v1593069801/explore-learning.jpg" />*/}
      </Helmet>
    </div>
  );
};

export default SEO;
